<template>
  <b-button
    size="sm"
    variant="danger"
    :disabled="waiting"
    @click.prevent="remove()"
    >X</b-button
  >
</template>
<script>
import Api from '@/components/mixins/api'

export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      waiting: false
    }
  },

  mixins: [Api],

  methods: {
    remove() {
      this.waiting = true
      this.apiGet({
        type: 'scheduler',
        method: 'remove',
        dbQuery: {
          _id: this.data._id
        }
      })
        .then(r => {
          // console.log('TCL: re -> r', r)
          this.waiting = false
          this.$emit('removed', r.deleted)
        })
        .catch(e => {
          this.waiting = false
          console.log('TCL: re -> e', e)
        })
    }
  }
}
</script>
