<template>
  <div>
    <b-modal
      ref="modal"
      v-model="showModal"
      :title="'Ustawienia ' + params.name"
      hide-footer
    >
      <div v-if="params">
        <!-- <pre style="color:#fff">{{params}}</pre> -->

        <div v-if="'configArray' in params">
          <div
            v-for="(itm, index) in params.configArray"
            :key="index"
            class="d-flex"
          >
            <div class="mr-auto p-2">{{ itm.name }}</div>
            <div class="p-2">
              <FunctionBtn
                :driver="params.driver"
                :func="params.config.functionName"
                :param="itm.param"
                :separator="params.config.separator"
                :type="itm.type"
              />
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showModal: false,
      params: false
    }
  },

  methods: {},
  mounted() {
    window.mBus.$on('settingsModal', data => {
      this.showModal = true
      this.params = data

      if ('params' in data.config) {
        this.params.configArray = Object.keys(data.config.params).map(itm => {
          if (typeof itm === 'string') {
            return { param: itm, name: data.config.params[itm], type: 'input' }
          } else {
            return { param: itm, name: data.config.params[itm].name }
          }
        })
      }
    })

    this.$refs.modal.$on('hide', () => {
      setTimeout(() => {
        this.params = false
      }, 200)
    })
  }
}
</script>

<style lang="scss"></style>
