import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import io from 'socket.io-client'
import moment from 'moment'

import './global-components'

// sentry
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'

require('vue2-animate/dist/vue2-animate.min.css')

Sentry.init({
  dsn: 'https://b15ccde964cf40e58b19eed52ac6698f@sentry.io/1851526',
  integrations: [
    new Integrations.Vue({ Vue, attachProps: true, logErrors: true })
  ]
})
// end sentry

Vue.use(require('vue-moment'))
Vue.config.productionTip = false

/**
 * Filters
 */
Vue.filter('formatDate', (value, format) => {
  return moment(value).format(format || 'DD/MM, H:mm:ss')
})

/**
 * mBus
 */

window.mBus = new Vue({
  data() {
    return {
      socket: io(process.env.VUE_APP_IO || '', { transports: ['websocket'] })
    }
  }
})

/**
 * Main vue instance
 */
const app = new Vue({
  router,
  store,
  data() {
    return {}
  },
  methods: {
    energyCost(minutes, power) {
      // power in WATT
      if (power) {
        const p = (minutes / 60) * (power / 1000) * 0.62 // 62 grosze
        return p.toFixed(4)
      }
      return 0
    }
  },

  mounted() {
    // config
    window.mBus.socket.on('config', m => {
      this.$store.commit('updateConfig', m)
      window.mBus.$emit('configUpdated', m)
    })

    // drivers data
    window.mBus.socket.on('driversData', m => {
      this.$store.commit('updateDriversData', m)
    })

    window.mBus.socket.on('common', m => {
      this.$store.commit('updateCommon', m)
    })

    // weather
    window.mBus.socket.on('weather', m => {
      this.$store.commit('updateWeather', m)
    })

    // inverter
    window.mBus.socket.on('inverter', m => {
      this.$store.commit('updateInverter', m)
    })

    window.mBus.socket.on('voltageStatsAll', m => {
      this.$store.commit('updateVoltage', m)
    })

    // scheduler data
    window.mBus.socket.on('schedulerData', m => {
      this.$store.commit('updateSchedulerData', m)
    })

    // monitoring
    window.mBus.socket.on('cam', data => {
      this.$store.commit('updateMonitoring', { ...data, updated: Date.now() })
    })

     // logger
     window.mBus.socket.on('log', m => {
      this.$store.commit('updateLogger', m)
    })

    // connection
    window.mBus.socket.on('connect', () => {
      console.log('connected', window.mBus.socket.id)
      const pass = localStorage.getItem('app-password') || false

      // save connection socket id 
      this.$store.commit('updateSocketId', window.mBus.socket.id)

      if (pass) {
        console.log('socket podłączony, wysyłam hasło')
        window.mBus.socket.emit('authentication', { token: pass })
      } else {
        console.log('brak hasla')
      }

      window.mBus.socket.on('authenticated', function() {
        console.log('Haslo prawidłowe')
        window.mBus.socket.emit('join', 'web')
      })
    })

    // reconnect sockets
    window.mBus.socket.on('reconnect_attempt', () => {
      window.mBus.socket.io.opts.transports = ['websocket']
    })
  },
  render: function(h) {
    return h(App)
  }
}).$mount('#app')

// only for testing
if (window.Cypress) {
  window.app = app
}
