<template>
  <a @click.prevent="showStats" class="stat-btn">
    <span v-if="!$slots.default">
      <i class="fas fa-chart-bar" />
    </span>
    <slot v-else />

    <b-modal
      v-if="showModal"
      ref="myModal"
      :title="'Statystyki ' + getName"
      hide-footer
    >
      <stat-modal
        :variable="variable"
        :variable-value="variableValue"
        :driver="driver"
        :group-by="groupBy"
        :data-param="dataParam"
      />
    </b-modal>
  </a>
</template>
<script>
import { mapState } from 'vuex'
import StatModal from './stat-btn-modal'

export default {
  props: {
    variable: String,
    driver: String,

    // avg / max / min / count
    dataParam: {
      type: String,
      default: 'avg'
    },

    groupBy: {
      type: String,
      default: 'hour'
    },

    variableValue: {
      type: [String, Boolean],
      default: null
    },

    name: {
      type: [String, Boolean],
      default: false
    }
  },

  components: {
    StatModal
  },

  data() {
    return {
      showModal: false
    }
  },

  computed: {
    ...mapState(['driversData', 'config']),

    getName() {
      const { current } = this.config[this.driver] || {}

      if (this.name) {
        return this.name
      } else {
        if (!current) {
          return this.variable
        }

        if (typeof current[this.variable] === 'object') {
          return current[this.variable].name
        } else {
          return current[this.variable]
        }
      }
    }
  },

  methods: {
    showStats() {
      this.showModal = true
      this.$nextTick(() => {
        this.$refs.myModal.show()
      })
    }
  },

  mounted() {
    this.$root.$on('bv::modal::hidden', () => {
      this.showModal = false
    })
  }
}
</script>

<style lang="scss" scoped>
.stat-btn {
  cursor: pointer;
}
</style>
