var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row"
  }, _vm._l(_vm.timeParams, function (param, key) {
    return _c('div', {
      key: key,
      staticClass: "col-6"
    }, [_c('div', [_c('small', [_vm._v(" " + _vm._s(param.label || key.toUpperCase()) + " ")])]), _c('FunctionBtn', {
      attrs: {
        "driver": _vm.driver,
        "type": "input",
        "func": _vm.func,
        "param": key,
        "size": "sm",
        "align": "center"
      }
    })], 1);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }