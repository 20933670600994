<template>
  <div class="d-inline-block scheduler">
    <div class="schedulers-info">
      <span class="badge badge-success" v-if="activeSchedulers">
        {{ activeSchedulers }}
      </span>

      <span class="badge badge-secondary" v-if="definedSchedulers">
        {{ definedSchedulers }}
      </span>
    </div>
    <a
      href="#"
      @click.prevent="open()"
      :class="btnClass"
      v-if="slots"
      :title="btnName"
    >
      <slot></slot>
    </a>

    <b-button @click="open()" :size="size" v-if="!slots" :class="btnClass">
      {{ btnName }}
    </b-button>

    <b-modal
      size="lg"
      ref="modal"
      :title="title"
      hide-footer
      @hidden="showContent = false"
    >
      <div v-if="showContent">
        <List :pin="pin" :driver="driver" ref="eventList" />
      </div>
    </b-modal>
  </div>
</template>
<script>
// Moduł działa tylko dla pinów, funkcje nie są obsługiwane

import Api from '@/components/mixins/api'
import List from '@/components/helpers/scheduler-list'

export default {
  props: {
    driver: {
      type: String,
      required: true
    },

    /**
     * Exist if scheduler is for digital
     */
    pin: {
      type: [String, Boolean],
      default: false
    },

    /**
     * Exist if scheduler is for functions
     */
    functionName: {
      type: [String, Boolean],
      default: false
    },

    btnName: {
      type: String,
      default: 'Scheduler'
    },

    btnClass: {
      type: String,
      default: ''
    },

    size: {
      type: String,
      default: 'md'
    },
    reversed: {
      type: Boolean,
      default: false
    }
  },

  components: {
    List
  },

  mixins: [Api],

  data() {
    return {
      checked: false,
      showContent: false
    }
  },

  computed: {
    title() {
      return `Zadania: ${this.config.name}`
    },

    slots() {
      return !!this.$slots.default
    },

    config() {
      return this.$store.state.config[this.driver].pins[this.pin]
    },

    schedulerData() {
      const { schedulerData } = this.$store.state || {}
      if (schedulerData.length) {
        return schedulerData.filter(item => {
          if (
            this.pin &&
            item.driver === this.driver &&
            item.pin === parseInt(this.pin)
          ) {
            return item
          }

          if (
            this.functionName &&
            item.driver === this.driver &&
            item.functionName === this.functionName
          ) {
            return item
          }
        })
      } else {
        return []
      }
    },

    definedSchedulers() {
      return this.schedulerData.length
    },

    activeSchedulers() {
      const byActive = item => item.active
      return this.schedulerData.filter(byActive).length
    }
  },

  mounted() {
    if (!this.pin && !this.functionName) {
      console.error(
        'Pin prop or function name does not exists in props object!'
      )
    }
  },

  methods: {
    open() {
      this.showContent = true
      this.$refs.modal.show()
    }
  }
}
</script>
<style lang="scss" scoped>
.scheduler {
  position: relative;

  .schedulers-info {
    position: absolute;
    right: -4px;
    top: -6px;

    span {
      padding: 1px 2px;
      font-size: 10px;
      border-radius: 2px;

      &:first-child {
        margin-right: 1px;
      }
    }
  }
}
</style>
