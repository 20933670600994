<template>
  <label class="switch" :class="{ disabled: loading }">
    <div class="loading" v-if="loading">
      <i class="fas fa-circle-notch fa-spin"></i>
    </div>
    <input
      type="checkbox"
      :checked="checked"
      @click.prevent="$emit('change')"
    />
    <span class="slider round"></span>
  </label>
</template>
<script>
export default {
  name: 'AppSwitch',

  props: {
    loading: {
      type: Boolean,
      default: false
    },

    checked: {
      type: [Boolean, Number],
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.slider {
  color: #000;
}
</style>
