var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "apex-container"
  }, [_c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.loading ? _c('div', {
    staticClass: "loading"
  }, [_c('i', {
    staticClass: "fas fa-circle-notch fa-spin"
  })]) : _vm._e()]), _c('div', {
    staticClass: "stat-options"
  }, [_c('b-button', {
    staticClass: "btn-rounded",
    attrs: {
      "variant": "outline-primary",
      "size": "sm"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.reload();
      }
    }
  }, [_c('i', {
    staticClass: "fas fa-sync-alt"
  }), _vm._v(" " + _vm._s(this.variable) + " ")])], 1), _c('apexchart', {
    ref: "apexChart",
    class: {
      'apex-loading': _vm.loading
    },
    attrs: {
      "height": "150",
      "type": "area",
      "options": _vm.apexOptions,
      "series": _vm.series
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }