var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "icon d-flex align-items-center justify-content-center",
    class: {
      active: _vm.$route.name == 'shower'
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push({
          name: 'shower'
        });
      }
    }
  }, [_c('div', [_c('i', {
    staticClass: "fas fa-shower",
    class: _vm.status
  })])]);

}
var staticRenderFns = []

export { render, staticRenderFns }