<template>
  <div id="menu">
    <header></header>

    <ul class="slideout-nav">
      <li>
        <router-link to="/" @click.native="hideMenu">Pulpit</router-link>
      </li>
  
      <li>
        <router-link :to="{ name: 'lights' }" @click.native="hideMenu">
          Światła
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: 'blinds' }" @click.native="hideMenu">
          Rolety
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: 'heating' }" @click.native="hideMenu">
          Ogrzewanie
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: 'settings' }" @click.native="hideMenu">
          Ustawienia
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: 'inverter' }" @click.native="hideMenu">
          Inverter
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: 'monitoring' }" @click.native="hideMenu">
          Kamery
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: 'password' }" @click.native="hideMenu">
          Hasło
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import Slideout from 'slideout'
// import { throttle } from 'lodash'

export default {
  data() {
    return {
      slideout: null
    }
  },

  methods: {
    hideMenu(e) {
      setTimeout(() => {
        this.slideout.close()
      }, 350)
    },

    // handleWidth: throttle(() => {
    //   if (window.innerWidth > 800) {
    //     this.slideout.open()
    //   } else {
    //     this.slideout.close()
    //   }
    //   console.log('width', window.innerWidth)
    // }, 1000)

    catchEvents() {
      this.slideout.on('beforeopen', data => {
        this.$store.commit('updateSidebar', { isMoving: true })
      })
      this.slideout.on('beforeclose', data => {
        this.$store.commit('updateSidebar', { isMoving: true })
      })

      this.slideout.on('translatestart', data => {
        this.$store.commit('updateSidebar', { isMoving: true })
      })

      this.slideout.on('open', () => {
        this.$store.commit('updateSidebar', { isOpen: true, isMoving: false })
        // console.log('is open')
      })
      this.slideout.on('close', () => {
        this.$store.commit('updateSidebar', { isOpen: false, isMoving: false })
        // console.log('is close')
      })
    }
  },
  watch: {
    slideout() {
      // włączanie akcji po załadowaniu biblioteki slideout
      this.catchEvents()
      // console.log('sidebar changed')
    },
    '$store.state.sidebar': {
      handler: value => {
        // console.log('sidebar, moving, open:', value.isMoving, value.isOpen)
      },
      deep: true
    }
  },

  mounted() {
    this.slideout = new Slideout({
      panel: document.getElementById('panel'),
      menu: document.getElementById('menu'),
      padding: 256,
      tolerance: 70
    })

    this.$root.$on('sidebarOpen', () => {
      this.slideout.open()
    })

    this.$root.$on('sidebarClose', () => {
      this.slideout.close()
    })

    // window.addEventListener('resize', this.handleWidth)
  },

  destroyed() {
    // window.removeEventListener('resize', this.handleWidth)
  }
}
</script>

<style lang="scss">
#menu {
  padding-top: 60px;
}

.toggle-button {
  padding: 7px 20px 5px;
  font-size: 1.8em;
  float: right;
  opacity: 0.8;
}
</style>
