
/**
 * @todo - add requestId, przy duplikacji requestow jest problem (nadpisywanie)
 */

export default {
  data() {
    return {
      apiQuery: false,
      apiResponse: false,
      apiLoading: false
    }
  },

  mounted() {
    window.mBus.socket.on('apiResponse', message => {
      // console.log('apiResponse', message)
      // this.$store.commit('updateApiResponse', m)
      this.apiParseResponse(message)
    })
  },

  methods: {
    apiGet(query, timeout = 5000) {
      return new Promise((resolve, reject) => {
        this.apiQuery = query
        this.apiLoading = true
        window.mBus.socket.emit('api', query)

        // console.log('api query', query)

        let time = 0
        const interval = setInterval(() => {
        
          // waiting for response
          if (this.apiResponse) {
            this.apiLoading = false

            clearInterval(interval)

            if (this.apiResponse.response) {
              console.log('api - duration:', time / 1000, 's')
              resolve(this.apiResponse.response)
              // reset api response
              this.apiResponse = false
            } else {
              // console.log('TCL: interval -> r', this.apiResponse)
              reject(this.apiResponse.error)

              // reset api response
              this.apiResponse = false
            }
          }

          // add 20 mili seconds to time
          time += 20

          // set timeout if not recieve response
          if (time > timeout) {
            this.apiLoading = false
            clearInterval(interval)
            reject(Error('Api.js: timeout', JSON.stringify(query)))
          }
        }, 10)
      })
    },

    apiParseResponse(data) {
      // console.log('TCL: apiParseResponse -> data', JSON.stringify(data.query), JSON.stringify(this.apiQuery))
      if (JSON.stringify(data.query) === JSON.stringify(this.apiQuery)) {
        if (data.response) {
          if (data.response.message) {
            data.response.status = this.apiParseMessage(data.response.message)
          }
        }
        console.log('recieve response', data.response, data.query)
        this.apiResponse = data
      }
    },

    apiParseMessage(message) {
      message = message.replace('Pin D', '')
      message = message.replace('set to ', '')
      message = message.split(' ')
      return { pin: message[0], value: parseInt(message[1]) }
    }
  }
}
