<template>
  <div style="color: #fff">
    <!-- <pre>{{ list }}</pre> -->
    <template v-for="itm in list">
      <Row
        :data="itm"
        :key="itm._id"
        :idData="idData"
        @toggleActive="toggleActive"
      />
    </template>

    <add-row
      :idData="{ pin: pin, driver: driver }"
      class="my-2"
      @added="find()"
      v-if="add"
      @close="add = false"
    />

    <b-button size="sm" class="mt-2" v-if="!add" @click.prevent="add = !add">
      Dodaj wpis
    </b-button>
  </div>
</template>
<script>
import Api from '@/components/mixins/api'
import Row from './scheduler-list-row'
import AddRow from './scheduler-list-row-edit'

export default {
  props: {
    pin: String,
    driver: String
  },

  components: {
    Row,
    AddRow
  },

  mixins: [Api],

  data() {
    return {
      list: false,
      add: false,
      interval: false
    }
  },

  computed: {
    idData() {
      return { pin: this.pin, driver: this.driver }
    }
  },

  methods: {
    find() {
      this.apiGet({
        type: 'scheduler',
        method: 'find',
        dbQuery: {
          pin: parseInt(this.pin),
          driver: this.driver
        }
      })
        .then(r => {
          this.list = r
        })
        .catch(e => {
          console.log('TCL: find -> e', e)
        })
    },

    /**
     * Toggle active state
     */
    toggleActive(data) {
      data.active = !data.active
      data.next = null

      this.apiGet({
        type: 'scheduler',
        method: 'update',
        dbObjectId: data._id,
        dbQuery: data
      })
        .then(r => {
          this.$emit('updated', true)
        })
        .catch(e => {
          console.log('Updated -> e', e)
        })
    }
  },

  mounted() {
    this.find()
    this.interval = setInterval(() => {
      this.find()
    }, 5000)
  },

  beforeDestroy() {
    clearInterval(this.interval)
  }
}
</script>
