var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.show ? _c('div', {
    staticClass: "login-box"
  }, [_c('p', {
    staticClass: "text-center"
  }, [_vm._v(" Aktualne hasło:"), _c('br'), _c('strong', [_vm._v(_vm._s(_vm.password))])]), _c('p', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.passwordField,
      expression: "passwordField"
    }],
    ref: "login",
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.passwordField
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.setPassword.apply(null, arguments);
      },
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.passwordField = $event.target.value;
      }
    }
  })]), _c('p', [_c('button', {
    staticClass: "btn btn-success btn-block",
    on: {
      "click": _vm.setPassword
    }
  }, [_vm._v(" Zapisz ")])])]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }