var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-button', {
    attrs: {
      "size": "sm",
      "variant": "danger",
      "disabled": _vm.waiting
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.remove();
      }
    }
  }, [_vm._v("X")]);

}
var staticRenderFns = []

export { render, staticRenderFns }