var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.valueOnly ? _c('div', {
    staticClass: "d-flex justify-content-between mx-3 mb-1"
  }, [_c('div', [_vm._v(" " + _vm._s(_vm.paramName) + " ")]), _c('strong', {
    staticClass: "ml-auto mr-3"
  }, [_vm._v(" " + _vm._s(_vm.paramValue) + " ")]), _c('StatBtn', {
    attrs: {
      "driver": _vm.driver,
      "variable": _vm.param
    }
  }, [_c('i', {
    staticClass: "fas fa-chart-bar"
  })])], 1) : _c('div', [_vm._v(" " + _vm._s(_vm.paramValue) + " ")]);

}
var staticRenderFns = []

export { render, staticRenderFns }