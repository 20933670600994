var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isHeating ? _c('div', {
    staticClass: "icon d-flex align-items-center justify-content-center",
    class: {
      active: _vm.$route.name == 'furnace'
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push({
          name: 'furnace'
        });
      }
    }
  }, [_c('div', [_c('i', {
    staticClass: "fas fa-fire",
    class: _vm.status
  })])]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }