<template>
  <span class="status mx-1" :class="color">
    <!-- {{status}} -->
  </span>
</template>
<script>
export default {
  props: {
    status: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    color() {
      return this.status ? 'color-green' : 'color-gray'
    }
  }
}
</script>

<style lang="scss" scoped>
.status {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 15px;
}
.color-gray {
  background: rgba(204, 204, 204, 0.507);
}
.color-green {
  background-color: rgb(96, 245, 128);
}
</style>
