<template>
  <AppBox :header="name">
    <div class="d-flex">
      <dir class="m-1 mt-2 p-0 ml-2">
        <StatBtn :driver="driver" :variable="statKey" :name="name">
          <i class="fas fa-chart-bar"></i>
        </StatBtn>
      </dir>

      <div class="ml-1" v-if="settings">
        <a href="" class="d-inline-block p-2" @click.prevent="showSettings">
          <i class="fas fa-cog"></i>
        </a>
      </div>

      <div class="ml-1">
        <Scheduler
          :pin="pin"
          :driver="driver"
          btnClass="d-inline-block p-2"
          :reversed="reversed"
        >
          <i class="fas fa-align-left"></i>
        </Scheduler>
      </div>

      <div class="ml-auto mr-2 mt-1">
        <SwitchBtn
          :driver="driver"
          :pin="pin"
          :history-key="historyKey"
          :reversed="reversed"
        />
      </div>
    </div>
  </AppBox>
</template>

<script>
import { mapState } from 'vuex'

import StatBtn from '@/components/helpers/stat-btn'
import SwitchBtn from '@/components/helpers/switch-btn'
import Scheduler from '@/components/helpers/scheduler.vue'

export default {
  name: 'SwitchBox',
  props: {
    driver: {
      type: String,
      required: true
    },
    pin: {
      type: [String, Number],
      required: true
    },
    reversed: {
      type: Boolean,
      defualt: false
    }
  },

  components: {
    SwitchBtn,
    Scheduler,
    StatBtn
  },

  data() {
    return {}
  },

  computed: {
    ...mapState(['config', 'driversData']),

    getConfigByPin() {
      return this.config[this.driver].pins[this.pin] || {}
    },

    historyKey() {
      return this.getConfigByPin.current || false
    },

    name() {
      return this.getConfigByPin.name || '[no name]'
    },

    settings() {
      if ('config' in this.getConfigByPin) {
        return true
      } else {
        return false
      }
    },

    statKey() {
      const { current } = this.getConfigByPin || {}
      return current
    }
  },
  methods: {
    // showStats() {
    //   window.mBus.$emit('statModal', {
    //     pin: this.pin,
    //     key: this.statKey,
    //     driver: this.driver,
    //     name: this.name
    //   })
    // },

    showSettings() {
      window.mBus.$emit('settingsModal', {
        key: this.statKey,
        driver: this.driver,
        name: this.name,
        config: this.getConfigByPin.config
      })
    }
  }
}
</script>
