var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "edit-bg p-3"
  }, [_vm.data ? _c('h6', [_vm._v("Edycja zadania")]) : _vm._e(), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-sm-6"
  }, [_c('div', {
    staticClass: "row"
  }, [_vm._m(0), _c('div', {
    staticClass: "col-8"
  }, [_c('b-form-input', {
    attrs: {
      "id": "cron"
    },
    model: {
      value: _vm.form.cron,
      callback: function ($$v) {
        _vm.$set(_vm.form, "cron", $$v);
      },
      expression: "form.cron"
    }
  }), _c('small', [_vm._v("min, godz, dzien mies, miesiac, dz tyg (0n-7)")])], 1)])]), _c('div', {
    staticClass: "col-sm-6"
  }, [_c('div', {
    staticClass: "row"
  }, [_vm._m(1), _c('div', {
    staticClass: "col-6"
  }, [_c('b-form-input', {
    attrs: {
      "id": "delay"
    },
    model: {
      value: _vm.form.delay,
      callback: function ($$v) {
        _vm.$set(_vm.form, "delay", $$v);
      },
      expression: "form.delay"
    }
  })], 1)])])]), _c('p', {
    staticClass: "my-2"
  }, [_vm.data ? _c('b-button', {
    staticClass: "px-4",
    attrs: {
      "variant": "success"
    },
    on: {
      "click": function ($event) {
        return _vm.save();
      }
    }
  }, [_vm._v("Zapisz")]) : _c('b-button', {
    staticClass: "px-4",
    attrs: {
      "variant": "success"
    },
    on: {
      "click": function ($event) {
        return _vm.add();
      }
    }
  }, [_vm._v("Dodaj")]), _c('b-button', {
    staticClass: "ml-2",
    attrs: {
      "variant": "secondary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.close();
      }
    }
  }, [_vm._v("Anuluj")])], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-4"
  }, [_c('label', {
    staticClass: "mt-1",
    attrs: {
      "for": "cron"
    }
  }, [_vm._v("Cron:")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-6"
  }, [_c('label', {
    staticClass: "m-0 p-0",
    attrs: {
      "for": "delay"
    }
  }, [_vm._v("Czas działania")]), _c('br'), _c('small', {
    staticClass: "m-0 p-0"
  }, [_vm._v("W minutach")])]);

}]

export { render, staticRenderFns }