var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "AppBox mt-4 mb-2"
  }, [_c('div', {
    staticClass: "box"
  }, [_vm.header ? _c('div', {
    staticClass: "header",
    domProps: {
      "innerHTML": _vm._s(_vm.header)
    }
  }) : _vm._e(), _vm._t("default")], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }