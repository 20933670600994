import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    socketId: null,
    sidebar: {
      isOpen: false,
      isMoving: false
    },
    lastTouchTime: new Date(),
    loaded: false,
    config: false,
    weather: false,
    inverter: false,
    voltage: null,
    monitoring: false,
    driversData: {},
    schedulerData: {},
    apiResponse: false,
    logger: [],
    common: {}
  },

  mutations: {
    setLastTouchTime(state) {
      state.lastTouchTime = new Date()
    },

    updateSocketId(state, payload) {
      state.socketId = payload
    },

    updateSidebar(state, payload) {
      state.sidebar = { ...state.sidebar, ...payload }
    },

    updateLoaded(state, payload) {
      state.loaded = payload
    },

    updateConfig(state, payload) {
      state.config = payload
    },

    updateCommon(state, payload) {
      state.common = { ...state.common, ...payload }
    },

    updateDriversData(state, payload) {
      state.driversData = { ...state.driversData, ...payload }
    },

    updateWeather(state, payload) {
      state.weather = payload
    },

    updateInverter(state, payload) {
      state.inverter = payload
    },

    updateVoltage(state, payload) {
      state.voltage = payload
    },

    updateSchedulerData(state, payload) {
      state.schedulerData = payload
    },

    updateApiResponse(state, payload) {
      state.apiResponse = payload
    },

    updateMonitoring(state, payload) {
      state.monitoring = { ...state.monitoring, ...payload }
    },

    updateLogger(state, payload) {
      state.logger = [...state.logger, payload]
    }
  },

  actions: {
    load({ state }) {
      return new Promise(resolve => {
        if (state.config) {
          resolve()
        } else {
          window.mBus.$on('configUpdated', () => {
            resolve()
          })
        }
      })
    }
  }
})
