<template>
  <div
    :class="['monitoring-floating', { 'monitoring-floating--active': active }]"
  >
    <b-button
      pill
      size="lg"
      variant="light"
      v-if="!active"
      class="expand-button"
      @click="active = !active"
    >
      <i class="fas fa-video" />
    </b-button>

    <b-button
      pill
      size="lg"
      v-if="active"
      variant="danger"
      class="close-button"
      @click="active = !active"
    >
      <i class="fas fa-times-circle" />
    </b-button>

    <div class="preview d-flex" v-if="active">
      <camera place="entrance" class="camera" />
      <camera place="terrace" class="camera" />
      <camera place="garage" class="camera" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'MonitoringFloating',

  components: {
    Camera: () => import('@/components/monitoring/Camera')
  },

  data() {
    return {
      active: false
    }
  },

  methods: {}
}
</script>
<style lang="scss" scoped>
.monitoring-floating {
  position: fixed;
  z-index: 100;
  right: 0;
  bottom: 0;
  width: 50px;
  height: 50px;

  transition: all 0.3s ease;

  .preview {
    .camera {
      max-height: 200px;
      max-width: 400px;
      margin: 0 0.5rem;
    }
  }

  .expand-button {
    position: absolute;
    right: 10px;
    top: -26px;
  }

  .close-button {
    position: absolute;
    right: 10px;
    top: -26px;
    font-size: 28px;
    padding: 3px 10px;
    border-radius: 100px;
  }

  &--active {
    padding: 10px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 250px;
    background: rgba($color: #000000, $alpha: 0.1);
  }
}
</style>
