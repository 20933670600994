<template>
  <div
    class="icon d-flex align-items-center justify-content-center"
    :class="{ active: $route.name == 'shower' }"
    @click="$router.push({ name: 'shower' })"
  >
    <div><i class="fas fa-shower" :class="status"></i></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ShowerIcon',

  data() {
    return {}
  },

  computed: {
    ...mapState(['driversData']),

    status() {
      const { piec } = this.driversData || {}
      const { pwod, grz_wod: grzWod } = piec || {}

      return pwod || grzWod ? 'color-red' : ''
    }
  }
}
</script>

<style lang="scss" scoped>
.icon {
  padding: 5px 10px 0;
  height: 60px;
  margin: 0 1px;
  display: inline-block;
  transition: background 0.3s ease;

  &.active {
    background: rgba($color: #000000, $alpha: 0.3);
  }

  i {
    display: inline-block;
    font-size: 1.6em;
    &.color-red {
      color: rgb(255, 113, 132);
    }
  }
}
</style>
