var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    staticClass: "stat-btn",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.showStats.apply(null, arguments);
      }
    }
  }, [!_vm.$slots.default ? _c('span', [_c('i', {
    staticClass: "fas fa-chart-bar"
  })]) : _vm._t("default"), _vm.showModal ? _c('b-modal', {
    ref: "myModal",
    attrs: {
      "title": 'Statystyki ' + _vm.getName,
      "hide-footer": ""
    }
  }, [_c('stat-modal', {
    attrs: {
      "variable": _vm.variable,
      "variable-value": _vm.variableValue,
      "driver": _vm.driver,
      "group-by": _vm.groupBy,
      "data-param": _vm.dataParam
    }
  })], 1) : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }