var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-loader"
  }, [_c('transition', {
    attrs: {
      "name": "slide"
    }
  }, [_vm.offline ? _c('div', {
    staticClass: "offline"
  }, [_vm._v(" OFFLINE: "), _vm._l(_vm.driversOffline, function (itm, index) {
    return _c('span', {
      key: index
    }, [_vm._v(" " + _vm._s(itm.toUpperCase()) + ", ")]);
  })], 2) : _vm._e()]), _c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.showLoader ? _c('div', {
    staticClass: "loading d-flex justify-content-center align-items-center"
  }, [_c('div', [_c('div', {
    staticClass: "text-center"
  }, [_c('i', {
    staticClass: "fas fa-circle-notch fa-spin"
  })]), _c('login')], 1)]) : _vm._e()])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }