var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AppBox', {
    attrs: {
      "header": _vm.name
    }
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('dir', {
    staticClass: "m-1 mt-2 p-0 ml-2"
  }, [_c('StatBtn', {
    attrs: {
      "driver": _vm.driver,
      "variable": _vm.statKey,
      "name": _vm.name
    }
  }, [_c('i', {
    staticClass: "fas fa-chart-bar"
  })])], 1), _vm.settings ? _c('div', {
    staticClass: "ml-1"
  }, [_c('a', {
    staticClass: "d-inline-block p-2",
    attrs: {
      "href": ""
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.showSettings.apply(null, arguments);
      }
    }
  }, [_c('i', {
    staticClass: "fas fa-cog"
  })])]) : _vm._e(), _c('div', {
    staticClass: "ml-1"
  }, [_c('Scheduler', {
    attrs: {
      "pin": _vm.pin,
      "driver": _vm.driver,
      "btnClass": "d-inline-block p-2",
      "reversed": _vm.reversed
    }
  }, [_c('i', {
    staticClass: "fas fa-align-left"
  })])], 1), _c('div', {
    staticClass: "ml-auto mr-2 mt-1"
  }, [_c('SwitchBtn', {
    attrs: {
      "driver": _vm.driver,
      "pin": _vm.pin,
      "history-key": _vm.historyKey,
      "reversed": _vm.reversed
    }
  })], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }