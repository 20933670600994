<template>
  <div class="main-loader">
    <transition name="slide">
      <div class="offline" v-if="offline">
        OFFLINE:
        <span v-for="(itm, index) in driversOffline" :key="index">
          {{ itm.toUpperCase() }},
        </span>
      </div>
    </transition>
    <transition name="fade">
      <div
        class="loading d-flex justify-content-center align-items-center"
        v-if="showLoader"
      >
        <div>
          <div class="text-center">
            <i class="fas fa-circle-notch fa-spin"></i>
          </div>
          <login />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Login from './login.vue'

export default {
  data() {
    return {
      offline: false,
      driversOffline: []
    }
  },
  components: {
    Login
  },
  computed: {
    showLoader() {
      return !this.$store.state.loaded
    },

    checkDriversData() {
      return Object.keys(this.$store.state.driversData).length
    },

    driversData() {
      return this.$store.state.driversData
    }
  },
  watch: {
    // check loaded drivers data
    checkDriversData(newVal) {
      if (newVal && !this.$store.state.loaded) {
        this.$store.commit('updateLoaded', true)
      }
    },

    // check if any driver is offline
    driversData(newVal) {
      const offline = []
      for (const key in newVal) {
        if (newVal[key].status !== 'online') {
          offline.push(key)
        }
      }
      this.driversOffline = offline
      this.offline = offline.length
    }
  }
}
</script>

<style lang="scss" scoped>
.main-loader {
  .loading {
    background: rgba($color: #000000, $alpha: 0.7);
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 100;
    i {
      font-size: 80px;
    }
  }
  .offline {
    position: fixed;
    z-index: 10000;
    width: 100vw;
    padding: 10px;
    font-size: 0.8rem;
    text-align: center;
    bottom: 0px;
    color: #fff;
    background: rgba($color: #c20d34, $alpha: 0.8);
    span {
      font-weight: bold;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s ease-in-out;
}
.slide-enter,
.slide-leave-to {
  transform: translateY(50px);
  opacity: 0;
}
</style>
