var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "buffor d-flex align-items-center justify-content-center",
    class: {
      active: _vm.$route.name === 'buffor'
    },
    on: {
      "click": _vm.click
    }
  }, [_vm.heatingType ? _c('i', {
    staticClass: "status-icon fas",
    class: _vm.heatingType
  }) : _vm._e(), _c('div', {
    staticClass: "icon"
  }, [_c('i', {
    staticClass: "fas",
    class: _vm.energy
  })])]);

}
var staticRenderFns = []

export { render, staticRenderFns }