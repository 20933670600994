<template>
  <div>
    <date-settings v-model="dateSettings" :loading="chartLoading" />

    <StatChart
      :driver="driver"
      :type="getTypeFromConfig"
      :variable="variable"
      :variable-value="variableValue"
      :data-param="dataParam"
      :group-by="getGroupBy"
      :date-from="getDateFrom"
      :date-to="getDateTo"
      @loading="setChartLoading"
      @formattedData="chartsData"
    />

    <div v-if="!hideList && dataFromChart">
      
      <p class="my-3">Szczegóły</p>

      <table class="table">
        <tbody>
          <tr v-for="(item, index) in dataFromChart" :key="index">
            <td>
              {{ item.lp }}
            </td>
            <td>
              {{ item[dataParam] }}
            </td>

            <td v-if="dataParam === 'count' && getPowerInWatt">
              {{ calculateCost(item.count) }}
            </td>
          </tr>
          <tr v-if="dataParam === 'count' && getPowerInWatt">
            <td></td>
            <td>Suma:</td>
            <td>{{ summary.cost }} zł, {{ summary.power }} kw</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import StatChart from './stat-chart'
import DateSettings from '@/components/helpers/date-settings'

export default {
  name: 'StatBtnModal',
  props: {
    variable: String,
    driver: String,

    // parametr ktory wyswietlac na statystykach
    // avg, max, min, count
    dataParam: {
      type: String,
      default: 'avg'
    },

    hideList: {
      type: Boolean,
      default: false
    },

    groupBy: {
      type: String,
      default: 'hour'
    },

    variableValue: {
      type: [String, Boolean],
      default: null
    }
  },

  components: {
    StatChart,
    DateSettings
  },

  data() {
    return {
      dateSettings: {}, // { from: string, to: string, groupBy: day|month|year }
      dataFromChart: false,
      summary: {}, // cost, power - if is count and 'power' variable exists
      chartLoading: false
    }
  },

  computed: {
    getDateFrom() {
      const { dateFrom } = this.dateSettings || {}
      return dateFrom || this.dateFrom
    },

    getDateTo() {
      const { dateTo } = this.dateSettings || {}
      return dateTo || this.dateTo
    },

    getGroupBy() {
      const { groupBy } = this.dateSettings || {}
      return groupBy || this.groupBy
    },

    config() {
      return this.$store.state.config
    },

    getVariableInfo() {
      const { current } = this.config[this.driver] || {}
      return current && current[this.variable]
    },

    getTypeFromConfig() {
      if (typeof this.getVariableInfo === 'object') {
        return this.getVariableInfo.type || this.type
      } else {
        return this.type
      }
    },

    // get cost for 1 KW
    getPowerCost() {
      const { cost1kw: defaultCost } = this.config[this.driver]
      const { cost1kw } = this.getVariableInfo
      return cost1kw || defaultCost || false
    },

    getPowerInWatt() {
      const { power } = this.getVariableInfo || {}
      return power
    }
  },

  methods: {
    setChartLoading(state) {
      this.chartLoading = state
    },

    chartsData(data) {
      this.dataFromChart = data
      this.calculateCostSum(data)
    },

    /**
     * calculate cost,
     * @parm time - in minutes - number
     */
    calculateCost(time) {
      return (
        (
          (this.getPowerInWatt / 1000) *
          this.getPowerCost *
          (time / 60)
        ).toFixed(2) +
        ` zł (${((this.getPowerInWatt / 1000) * (time / 60)).toFixed(2)}kw) `
      )
    },

    calculateCostSum(data) {
      this.summary = {
        power: 0,
        cost: 0
      }

      const summary = {
        cost: 0,
        power: 0
      }

      // zliczam w przypadku okreslonego kosztu i typu count
      // console.log(this.getPowerInWatt, this.getTypeFromConfig, data)
      if (
        this.getPowerInWatt &&
        this.getTypeFromConfig === 'count' &&
        data.length
      ) {
        data.forEach(item => {
          // console.log(item.count)
          summary.cost += parseFloat(this.calculateCost(item.count))
          summary.power += parseFloat(
            (this.getPowerInWatt / 1000) * (item.count / 60)
          )
        })
      }

      this.summary = {
        cost: summary.cost.toFixed(2),
        power: summary.power.toFixed(2)
      }
    }
  }
}
</script>
