<template>
  <div v-if="$scopedSlots.default">
    <slot
      name="default"
      :loading="loading"
      :funcObj="funcObj"
      :btnStatus="btnStatus"
      :btnNextValue="btnNextValue"
      :btnCurrentValue="btnCurrentValue"
    />
  </div>
  <div :class="['d-flex', alignClass]" v-else>
    <h5 class="text-center my-2 text-weight-bold" v-if="showName">
      {{ name }}
    </h5>

    <template v-if="type === 'input'">
      <b-input-group>
        <b-form-input
          v-model="inputValue"
          :disabled="loading"
          @keyup.enter="saveValue"
          class="input-text"
        />

        <b-input-group-append>
          <b-btn
            v-if="!inputValue"
            variant="outline-info"
            @click.prevent="getValue"
            :disabled="loading"
            v-html="parseName('$close')"
          />
          <b-btn
            v-else
            variant="outline-success"
            @click.prevent="saveValue"
            :disabled="loading"
            v-html="parseName('$open')"
          />
        </b-input-group-append>
      </b-input-group>
    </template>

    <template v-if="type === 'floor'">
      <b-input-group>
        <b-input-group-prepend>
          <b-btn
            variant="outline-light"
            :size="size"
            @click="setLower"
            v-html="parseName('$close')"
            :disabled="!inputValue"
          />
        </b-input-group-prepend>

        <b-form-input
          v-model="inputValue"
          :size="size"
          @click.native="getValue"
          class="input-text"
        />

        <b-input-group-append>
          <b-btn
            variant="outline-light"
            :size="size"
            @click="setUpper"
            v-html="parseName('$open')"
            :disabled="!inputValue"
          />
        </b-input-group-append>
      </b-input-group>
    </template>

    <template v-if="!type">
      <b-button-group
        v-for="(itm, index) in funcObj.value"
        :key="index"
        class="buttons"
      >
        <b-button
          variant="outline-light"
          :pressed="funcObj.return[index] === btnStatus"
          :size="size"
          @click="send(itm.toString().replace('$', ''))"
          v-html="parseName(itm, index, funcObj)"
        />
      </b-button-group>
    </template>
    <!-- {{ getCurrentValue}} -->
    <slot name="append"></slot>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'FunctionBtn',
  props: {
    align: {
      type: String,
      default: 'left'
    },

    showName: {
      type: Boolean,
      default: false
    },

    showDescription: {
      type: Boolean,
      default: false
    },

    driver: {
      type: String,
      required: true
    },

    func: {
      type: String,
      required: true
    },

    type: { default: false },

    size: {
      type: String,
      default: 'lg'
    },

    autoload: {
      type: Boolean,
      default: false
    },

    /**
     * Auto refresh every X seconds - default 10
     */
    autoRefresh: {
      type: [Boolean, Number],
      default: false
    },

    step: {
      type: Number,
      default: 1
    },

    separator: {
      type: [String, Boolean],
      default: false
    },

    param: {
      type: [String, Boolean],
      default: false
    }
  },

  data() {
    return {
      loading: false,
      socket: window.mBus.socket,
      reloadInterval: null,
      btnStatus: null,
      inputValue: null
    }
  },

  computed: {
    ...mapState(['config', 'driversData']),

    alignClass() {
      return this.align === 'center' ? 'justify-content-center' : ''
    },

    getDriverFunctionConfig() {
      return this.config[this.driver].functions[this.func]
    },

    // get current value form driver data
    getCurrentValueFromDriversData() {
      const { current } = this.getDriverFunctionConfig || {}

      if (current) {
        return this.driversData[this.driver][current]
      } else {
        return null
      }
    },

    funcObj() {
      return this.getDriverFunctionConfig
    },

    // get name from config
    name() {
      return this.getDriverFunctionConfig.name || this.func
    },

    getSeparator() {
      return this.separator || this.getDriverFunctionConfig.separator || false
    },

    /**
     * Następna wartość w tablicy np. jest auto, następne jest on, off
     */
    btnNextValue() {
      if (this.btnStatus !== null && 'value' in this.getDriverFunctionConfig) {
        const index = this.getDriverFunctionConfig.return.indexOf(
          this.btnStatus
        )
        const valueLength = this.getDriverFunctionConfig.value.length
        if (valueLength - 1 === index) {
          return this.getDriverFunctionConfig.value[0]
        } else {
          return this.getDriverFunctionConfig.value[index + 1]
        }
      } else {
        return ''
      }
    },

    /**
     * jak wyzej tylko zwraca aktualna wartosc
     */
    btnCurrentValue() {
      if (this.btnStatus !== null && 'value' in this.getDriverFunctionConfig) {
        const index = this.getDriverFunctionConfig.return.indexOf(
          this.btnStatus
        )
        return this.getDriverFunctionConfig.value[index]
      } else {
        return ''
      }
    }
  },

  watch: {
    getCurrentValueFromDriversData: {
      handler(value) {
        /**
         * Jesli status istnieje w danych sterownika to po aktulizacji przypisz wartosc
         */
        if (value !== null) this.btnStatus = value
      },
      immediate: true
    }
  },

  methods: {
    getValue() {
      if (this.loading) {
        console.error('Wait for response ;)')
        return
      }

      this.loading = true

      this.socket.emit('api', {
        type: 'function',
        driver: this.driver,
        func: this.func,
        params: this.param
      })
    },

    saveValue() {
      this.loading = true
      this.socket.emit('api', {
        type: 'function',
        driver: this.driver,
        func: this.func,
        params: this.getSeparator
          ? this.param + this.getSeparator + this.inputValue
          : this.inputValue
      })
    },

    // dla podlogowki (mnozenie * 10)
    setLower() {
      const value = this.inputValue - this.step

      this.loading = true

      this.socket.emit('api', {
        type: 'function',
        driver: this.driver,
        func: this.func,
        params: this.param + this.separator + value * 10
      })
    },

    setUpper() {
      const value = this.inputValue + this.step

      this.loading = true

      this.socket.emit('api', {
        type: 'function',
        driver: this.driver,
        func: this.func,
        params: this.param + this.separator + value * 10
      })
    },

    // uzywane dla przyciskow standardowych
    send(param) {
      this.loading = true

      this.socket.emit('api', {
        type: 'function',
        driver: this.driver,
        func: this.func,
        params:
          'separator' in this.funcObj
            ? this.param + this.funcObj.separator + param
            : param
      })
    },

    // może zawierać ikony ...
    parseName(name, index, funcObj) {
      if (funcObj && 'btnNames' in funcObj) {
        name = funcObj.btnNames[index]
      }

      name = name.toString()

      if (this.loading) {
        return '<i class="fas fa-circle-notch fa-spin"></i>'
      } else {
        switch (name.toLowerCase()) {
          case '$open':
            return '<i class="fas fa-angle-up"></i>'
          case '$close':
            return '<i class="fas fa-angle-down"></i>'
          default:
            return name.replace('$', '').toUpperCase()
        }
      }
    },

    handleApiResponse() {
      this.socket.on('apiResponse', m => {
        if (
          m.query.driver === this.driver &&
          m.query.func === this.func &&
          m.query.type === 'function'
        ) {
          // zwrot z funkcji zawierajacej parametry
          if (this.param) {
            // console.log('z params', m)
            const check = m.query.params.split(this.separator)

            if (this.param === check[0]) {
              if (this.type === 'floor') {
                // console.log('z floor', m)
                this.inputValue = m.response.return_value / 10
              } else {
                this.inputValue = m.response.return_value
              }
            }

          } else {
            this.btnStatus = m.response.return_value
          }
          this.loading = false
        }
      })
    }
  },

  mounted() {
    if (this.autoload) {
      this.getValue()
    }

    if (this.autoRefresh) {
      const intervalTime =
        this.autoRefresh !== true ? parseInt(this.autoRefresh) : 10

      if (intervalTime < 5)
        console.error('Refresh time should be lenger than 4 seconds ')

      this.reloadInterval = setInterval(this.getValue, intervalTime * 1000)
    }

    this.handleApiResponse()
  },

  beforeDestroy() {
    this.socket.off('apiResponse')
    this.reloadInterval && clearInterval(this.reloadInterval)
  }
}
</script>

<style lang="scss" scoped>
.buttons button.btn {
  margin-right: 5px;
  i {
    font-size: 1.8em !important;
  }
}
.input-text[disabled],
.input-text[readonly],
.input-text {
  text-align: center;
  max-width: 100%;
  color: #fff;
  border-color: rgba($color: #fff, $alpha: 0.2);
  background: rgba($color: #fff, $alpha: 0.1);
}
</style>
