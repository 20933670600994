var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-inline-block scheduler"
  }, [_c('div', {
    staticClass: "schedulers-info"
  }, [_vm.activeSchedulers ? _c('span', {
    staticClass: "badge badge-success"
  }, [_vm._v(" " + _vm._s(_vm.activeSchedulers) + " ")]) : _vm._e(), _vm.definedSchedulers ? _c('span', {
    staticClass: "badge badge-secondary"
  }, [_vm._v(" " + _vm._s(_vm.definedSchedulers) + " ")]) : _vm._e()]), _vm.slots ? _c('a', {
    class: _vm.btnClass,
    attrs: {
      "href": "#",
      "title": _vm.btnName
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.open();
      }
    }
  }, [_vm._t("default")], 2) : _vm._e(), !_vm.slots ? _c('b-button', {
    class: _vm.btnClass,
    attrs: {
      "size": _vm.size
    },
    on: {
      "click": function ($event) {
        return _vm.open();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.btnName) + " ")]) : _vm._e(), _c('b-modal', {
    ref: "modal",
    attrs: {
      "size": "lg",
      "title": _vm.title,
      "hide-footer": ""
    },
    on: {
      "hidden": function ($event) {
        _vm.showContent = false;
      }
    }
  }, [_vm.showContent ? _c('div', [_c('List', {
    ref: "eventList",
    attrs: {
      "pin": _vm.pin,
      "driver": _vm.driver
    }
  })], 1) : _vm._e()])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }