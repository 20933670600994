var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row mb-4"
  }, [_c('AppBox', {
    staticClass: "col-12",
    attrs: {
      "header": "Temperatura zewnętrzna"
    }
  }, [_c('stat-chart', {
    attrs: {
      "driver": "piec",
      "variable": "t18"
    }
  })], 1)], 1), _c('div', [_vm._v(" home... glowne opcje "), _c('p', [_vm._v("Io url: " + _vm._s(_vm.ioUrl))])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }