<template>
  <div id="app">
    <main-loader />
    <app-screen-overlay />

    <HeaderBar />
    <Slideout />

    <main id="panel" class="container-fluid" style="overflow: hidden">
      <div class="main-content">
        <transition name="fade" mode="out-in">
          <router-view />
        </transition>
      </div>
    </main>

    <monitoring-floating v-if="currentRoute !== 'monitoring'" />

    <settings-modal />
  </div>
</template>
<script>
import HeaderBar from '@/components/header-bar.vue'
import MainLoader from './components/helpers/main-loader.vue'
import MonitoringFloating from '@/components/monitoring/MonitoringFloating'
import SettingsModal from './components/helpers/settings-modal.vue'
import Slideout from './components/helpers/slideout.vue'
import AppScreenOverlay from './components/common/AppScreenOverlay.vue'

export default {
  name: 'App',

  data() {
    return {}
  },

  components: {
    AppScreenOverlay,
    HeaderBar,
    MainLoader,
    MonitoringFloating,
    SettingsModal,
    Slideout
  },

  computed: {
    currentRoute() {
      const { name } = this.$route
      return name
    }
  }
}
</script>

<style lang="scss">
@import './scss/app.scss';

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  position: relative;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.main-content {
  padding-top: 60px;
}
</style>
