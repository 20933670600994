<template>
  <div
    class="icon d-flex align-items-center justify-content-center"
    :class="{ active: $route.name == 'ventilation' }"
    @click="$router.push({ name: 'ventilation' })"
  >
    <div><i class="fab fa-galactic-republic" :class="status"></i></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'VentilationIcon',
  data() {
    return {}
  },
  computed: {
    ...mapState(['driversData']),
    status() {
      if ('swiatlo' in this.driversData) {
        if (this.driversData.swiatlo.w1_49) {
          return 'text-warning'
        }
      }
      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
.icon {
  padding: 2px 10px 0;
  height: 60px;
  margin: 0 1px;
  display: inline-block;
  transition: background 0.3s ease;

  &.active {
    background: rgba($color: #000000, $alpha: 0.3);
  }

  i {
    display: inline-block;
    // display: inline-block;
    font-size: 1.6em;
    &.color-red {
      color: rgb(255, 113, 132);
    }
  }
}
</style>
