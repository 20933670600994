var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.code ? _c('div', {
    staticClass: "icon"
  }, [_c('img', {
    style: {
      width: `${_vm.size}px`
    },
    attrs: {
      "src": `https://developer.accuweather.com/sites/default/files/${_vm.addZeroPrefix(_vm.code)}-s.png`,
      "alt": ""
    }
  })]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }