import Vue from 'vue'
import Router from 'vue-router'
import store from './store'
import Home from './views/Home.vue'

// disable navigation duplicated
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/lights',
      name: 'lights',
      component: () => import('./views/Lights.vue')
    },
    {
      path: '/blinds',
      name: 'blinds',
      component: () => import('./views/Blinds.vue')
    },
    {
      path: '/heating',
      name: 'heating',
      component: () => import('./views/Heating.vue')
    },
    {
      path: '/inverter',
      name: 'inverter',
      component: () => import('./views/Inverter.vue')
    },
    {
      path: '/settings',
      name: 'settings',
      component: () => import('./views/Settings.vue')
    },
    {
      path: '/weather',
      name: 'weather',
      component: () => import('./views/Weather.vue')
    },
    {
      path: '/buffor',
      name: 'buffor',
      component: () => import('./views/Buffor.vue')
    },
    {
      path: '/furnace',
      name: 'furnace',
      component: () => import('./views/Furnace.vue')
    },
    {
      path: '/office',
      name: 'office',
      component: () => import('./views/Office.vue')
    },
    {
      path: '/shower',
      name: 'shower',
      component: () => import('./views/Shower.vue')
    },
    {
      path: '/password',
      name: 'password',
      component: () => import('./views/Password.vue')
    },
    {
      path: '/monitoring',
      name: 'monitoring',
      component: () => import('./views/Monitoring.vue')
    },
    {
      path: '/ventilation',
      name: 'ventilation',
      component: () => import('./views/Ventilation.vue')
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.name === 'password') {
    next()
  } else {
    store
      .dispatch('load')
      .then(() => {
        next()
      })
      .catch(e => console.log('router before error from load', e))
  }
})

export default router
