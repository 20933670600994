<template>
  <div
    v-if="isHeating"
    class="icon d-flex align-items-center justify-content-center"
    :class="{ active: $route.name == 'furnace' }"
    @click="$router.push({ name: 'furnace' })"
  >
    <div><i class="fas fa-fire" :class="status"></i></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Furnace',
  data() {
    return {}
  },
  computed: {
    ...mapState(['driversData', 'weather']),

    isActive() {
      const { piec } = this.driversData || {}
      return piec && piec.ppie
    },

    isHeating() {
      if ('piec' in this.driversData) {
       return this.driversData.piec.ppie
      }
      return null
    },

    status() {
      if ('piec' in this.driversData) {
        if (this.driversData.piec.ppie) {
          return 'color-red'
        }
      }
      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
.icon {
  padding: 2px 10px 0;
  height: 60px;
  margin: 0 1px;
  display: inline-block;
  transition: background 0.3s ease;

  &.active {
    background: rgba($color: #000000, $alpha: 0.3);
  }

  i {
    display: inline-block;
    // display: inline-block;
    font-size: 1.6em;
    &.color-red {
      color: rgb(255, 113, 132);
    }
  }
}
</style>
