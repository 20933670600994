<template>
  <div>
    <div class="d-flex w-100 justify-content-between">
      <div>
        <button
          class="btn btn-sm btn-primary"
          @click="changeDate('left')"
          :disabled="settings.groupBy === '' || loading"
        >
          <i class="fas fa-chevron-left"></i>
        </button>
      </div>
      <div>
        <b-button-group>
          <b-button
            variant="primary"
            :pressed="settings.groupBy === 'hour'"
            :disabled="loading"
            @click="changeGroup('hour')"
          >
            H
          </b-button>
          <b-button
            variant="primary"
            :pressed="settings.groupBy === 'day'"
            :disabled="loading"
            @click="changeGroup('day')"
          >
            D
          </b-button>
          <b-button
            variant="primary"
            :pressed="settings.groupBy === 'month'"
            :disabled="loading"
            @click="changeGroup('month')"
          >
            M
          </b-button>
          <b-button
            variant="primary"
            :pressed="settings.groupBy === ''"
            :disabled="loading"
            @click="changeGroup('')"
          >
            ALL
          </b-button>
        </b-button-group>
      </div>
      <div>
        <button
          class="btn btn-sm btn-primary"
          @click="changeDate('right')"
          :disabled="settings.groupBy === '' || loading"
        >
          <i class="fas fa-chevron-right"></i>
        </button>
      </div>
    </div>
    <div class="d-flex justify-content-between my-2 w-100">
      <div>
        {{ this.settings.dateFrom | formatDate('YYYY-MM-DD HH:mm') }}
      </div>
      <div>
        {{ this.settings.dateTo | formatDate('YYYY-MM-DD HH:mm') }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },

    value: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      settings: {
        dateFrom: this.$moment()
          .startOf('day')
          .format(),
        dateTo: this.$moment()
          .endOf('day')
          .format(),
        groupBy: 'hour'
      }
    }
  },

  watch: {
    value(newVal) {
      this.settings = newVal
    }
  },

  methods: {
    // change group
    changeGroup(group) {
      this.settings.groupBy = group
      this.settings.dateTo = this.$moment().format()

      switch (group) {
        case 'hour':
          this.settings.dateFrom = this.$moment()
            .subtract(1, 'd')
            .format()
          break

        case 'day':
          this.settings.dateFrom = this.$moment()
            .subtract(14, 'd')
            .format()
          break

        case 'month':
          this.settings.dateFrom = this.$moment()
            .subtract(12, 'M')
            .format()
          break

        default:
          this.settings.dateFrom = this.$moment()
            .subtract(36, 'M')
            .format()
          break
      }
      this.emitInput()
    },

    changeDate(direction) {
      switch (this.settings.groupBy) {
        case 'hour':
          if (direction === 'left') {
            this.settings.dateTo = this.settings.dateFrom
            this.settings.dateFrom = this.$moment(this.settings.dateFrom)
              .subtract(1, 'd')
              .format()
          } else {
            this.settings.dateFrom = this.settings.dateTo
            this.settings.dateTo = this.$moment(this.settings.dateTo)
              .add(1, 'd')
              .format()
          }
          break

        case 'day':
          if (direction === 'left') {
            this.settings.dateTo = this.settings.dateFrom
            this.settings.dateFrom = this.$moment(this.settings.dateFrom)
              .subtract(14, 'd')
              .format()
          } else {
            this.settings.dateFrom = this.settings.dateTo
            this.settings.dateTo = this.$moment(this.settings.dateTo)
              .add(14, 'd')
              .format()
          }
          break

        case 'month':
          if (direction === 'left') {
            this.settings.dateTo = this.settings.dateFrom
            this.settings.dateFrom = this.$moment(this.settings.dateFrom)
              .subtract(12, 'M')
              .format()
          } else {
            this.settings.dateFrom = this.settings.dateTo
            this.settings.dateTo = this.$moment(this.settings.dateTo)
              .add(12, 'M')
              .format()
          }
          break

        default:
          break
      }
      this.emitInput()
    },

    // emit input; object {}
    emitInput() {
      this.$emit('input', {
        dateFrom: this.settings.dateFrom,
        dateTo: this.settings.dateTo,
        groupBy: this.settings.groupBy
      })
    },

    // update settings data
    updateSettings(data) {
      this.settings = { ...data }
    }
  }
}
</script>
