<template>
  <div class="main-header" :class="{ dark: dark }">
    <div class="d-flex">
      <div class="mr-auto">
        <span class="toggle-button" @click="toggle()">
          <transition name="fade" mode="out-in">
            <i class="fas fa-bars" v-if="!isOpen" key="1"></i>
            <i class="fas fa-times" v-else key="2"></i>
          </transition>
        </span>
      </div>

      <transition name="fade" mode="out-in">
        <div class="d-flex" v-if="hidden">
          <div class="d-none d-sm-block">
            <InverterIcon />
          </div>
          <ventilation-icon />
          <shower-icon />
          <furnace-icon />
          <buffor-icon />

          <weather-icon :temp="true" />
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import WeatherIcon from '@/components/helpers/weather-icon.vue'
import BufforIcon from '@/components/heating/buffor-icon.vue'
import FurnaceIcon from '@/components/heating/furnace-icon.vue'
import ShowerIcon from '@/components/heating/shower-icon.vue'
import VentilationIcon from '@/components/ventilation/ventilation-icon.vue'

export default {
  components: {
    WeatherIcon,
    BufforIcon,
    FurnaceIcon,
    ShowerIcon,
    VentilationIcon,
    InverterIcon: () => import('@/components/inverter/InverterIcon')
  },

  data() {
    return {
      scrollY: null
    }
  },

  computed: {
    isOpen() {
      return this.$store.state.sidebar.isOpen
    },

    hidden() {
      if (this.isOpen) {
        return false
      } else {
        return !this.$store.state.sidebar.isMoving
      }
    },

    dark() {
      return !this.isOpen && this.scrollY > 60
    }
  },

  watch: {},

  methods: {
    toggle() {
      if (this.isOpen) {
        this.$root.$emit('sidebarClose')
      } else {
        this.$root.$emit('sidebarOpen')
      }
    }
  },

  mounted() {
    window.addEventListener('scroll', e => {
      this.scrollY = Math.floor(window.scrollY)
    })
  }
}
</script>

<style lang="scss" scoped>
// header

.main-header {
  transition: all 0.3s ease;

  height: 60px;
  background-color: rgba($color: #000000, $alpha: 0.4);
  &.dark {
    background-color: rgba($color: #000000, $alpha: 0.8);
  }

  padding: 0 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;

  .toggle-button {
    width: 30px;
    padding: 15px 0;
    margin: 0 5px 0 0;
    color: #fff;
    font-size: 1.7em;
    line-height: 1em;
    display: block;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>
