<template>
  <div
    class="
      app-screen-overlay
      d-flex
      justify-content-center
      align-items-center
      flex-column
    "
    v-if="isVisible"
  >
    <div class="flex-grow-1">
      <div class="time d-flex align-items-baseline justify-content-center">
        <roller :text="`${hour}:${min}`" class="ml-3" />
        <span class="seconds"><roller :text="seconds.toString()" /></span>
      </div>

      <div class="text-center current-date">
        <p>{{ currentDate }}</p>
      </div>

      <div class="d-flex justify-content-center p-1">
        <!-- temperatura -->
        <div
          class="d-flex align-items-center p-2 info-icon"
          @click="
            $router.push({ name: 'weather' });
            close();
          "
        >
          <i class="fas" :class="outsideTempIcon" />
          <span class="ml-1"> {{ piec.t18 }} </span>&deg;
        </div>
      </div>

      <div class="d-flex justify-content-center">
        <!-- woda -->
        <div
          class="d-flex align-items-center p-2 info-icon"
          :class="{ active: piec.pwod === 1 || piec.grz_wod === 1 }"
        >
          <button class="btn d-flex">
            <i class="fas fa-shower" />
            <span class="ml-2 text-uppercase" style="line-height: 1.1rem">
              {{ piec.t8 }}<br />
              <small>{{ piec.t9 }}</small>
            </span>
          </button>
        </div>

        <!-- grzejniki -->
        <div
          class="d-flex align-items-center p-2 info-icon"
          :class="{ active: piec.pgrz === 1 }"
        >
          <FunctionBtn driver="piec" func="pompgrzejniki" ref="pompgrzejniki">
            <template #default="{ btnNextValue, btnCurrentValue }">
              <button
                class="btn"
                @click="$refs.pompgrzejniki.send(btnNextValue)"
              >
                <i class="fas fa-hot-tub" />
                <span class="ml-2 text-uppercase">{{ btnCurrentValue }}</span>
              </button>
            </template>
          </FunctionBtn>
        </div>

        <!-- bufor -->
        <div class="d-flex align-items-center p-2 info-icon">
          <buffor-icon
            class="p-0"
            no-redirect
            @click="
              $router.push({ name: 'buffor' });
              close();
            "
          />
          <span>
            {{ piec.t4 }}
          </span>
          &deg;
        </div>

        <!-- piec -->
        <div
          class="d-flex align-items-center p-2 info-icon active"
          v-if="piec.ppie === 1"
          @click="
            $router.push({ name: 'furnace' });
            close();
          "
        >
          <i class="fas fa-fire" />
          <span class="ml-1"> {{ piec.t0 }} </span>&deg;
        </div>
      </div>

      <div>
        <InverterIcon />
      </div>
    </div>
    <div class="d-flex justify-content-center mb-5">
      <button class="btn btn-light" @click="close">Zamknij</button>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import Roller from "vue-roller";

import dayjs from "dayjs";
import "dayjs/locale/pl";
import BufforIcon from "../heating/buffor-icon.vue";

export default {
  name: "AppScreenOverlay",

  components: {
    Roller,
    BufforIcon,
    InverterIcon: () => import("@/components/inverter/InverterIcon"),
  },

  data() {
    return {
      isTouchDevice: "ontouchstart" in document.documentElement,
      isVisible: false,
      timeInterval: null,
      date: dayjs(),
      hour: 0,
      min: 0,
      seconds: 0,
      showAfter: 90, // show overflow after x seconds
    };
  },

  computed: {
    ...mapState(["lastTouchTime", "driversData"]),

    currentDate() {
      return this.date.locale("pl").format("dddd, D MMMM YYYY");
    },

    piec() {
      const { piec } = this.driversData || {};
      return piec;
    },

    outsideTempIcon() {
      let icon = "empty";
      if (this.piec.t18 > 5) icon = "quarter";
      if (this.piec.t18 > 15) icon = "half";
      if (this.piec.t18 > 20) icon = "three-quarters";
      if (this.piec.t18 > 25) icon = "full";

      return `fa-thermometer-${icon}`;
    },
  },

  watch: {
    isVisible: {
      // lock
      handler: (value) => {
        document.documentElement.style.overflow = value ? "hidden" : "auto";
      },
      immediate: true,
    },
  },

  methods: {
    checkTime() {
      const now = new Date();
      const lastTouch = this.lastTouchTime.getTime();
      const timePassed = (now.getTime() - lastTouch) / 1000 > this.showAfter;

      // show owerflow when time has passed
      this.isVisible = timePassed;

      // update current date
      this.date = dayjs();

      // update time
      this.hour = now.getHours() < 10 ? `0${now.getHours()}` : now.getHours();
      this.min =
        now.getMinutes() < 10 ? `0${now.getMinutes()}` : now.getMinutes();
      this.seconds =
        now.getSeconds() < 10 ? `0${now.getSeconds()}` : now.getSeconds();
    },

    handleTouchEvent() {
      if (!this.isVisible) {
        this.$store.commit("setLastTouchTime");
      }
    },

    close() {
      this.$store.commit("setLastTouchTime");
    },
  },

  mounted() {
    if (this.isTouchDevice) {
      this.timeInterval = setInterval(this.checkTime, 1000);

      window.addEventListener("touchstart", this.handleTouchEvent);
    }
  },

  beforeDestroy() {
    this.timeInterval && clearInterval(this.timeInterval);
    window.removeEventListener("touchstart", this.handleTouchEvent);
  },
};
</script>
