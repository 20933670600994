var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('date-settings', {
    attrs: {
      "loading": _vm.chartLoading
    },
    model: {
      value: _vm.dateSettings,
      callback: function ($$v) {
        _vm.dateSettings = $$v;
      },
      expression: "dateSettings"
    }
  }), _c('StatChart', {
    attrs: {
      "driver": _vm.driver,
      "type": _vm.getTypeFromConfig,
      "variable": _vm.variable,
      "variable-value": _vm.variableValue,
      "data-param": _vm.dataParam,
      "group-by": _vm.getGroupBy,
      "date-from": _vm.getDateFrom,
      "date-to": _vm.getDateTo
    },
    on: {
      "loading": _vm.setChartLoading,
      "formattedData": _vm.chartsData
    }
  }), !_vm.hideList && _vm.dataFromChart ? _c('div', [_c('p', {
    staticClass: "my-3"
  }, [_vm._v("Szczegóły")]), _c('table', {
    staticClass: "table"
  }, [_c('tbody', [_vm._l(_vm.dataFromChart, function (item, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(" " + _vm._s(item.lp) + " ")]), _c('td', [_vm._v(" " + _vm._s(item[_vm.dataParam]) + " ")]), _vm.dataParam === 'count' && _vm.getPowerInWatt ? _c('td', [_vm._v(" " + _vm._s(_vm.calculateCost(item.count)) + " ")]) : _vm._e()]);
  }), _vm.dataParam === 'count' && _vm.getPowerInWatt ? _c('tr', [_c('td'), _c('td', [_vm._v("Suma:")]), _c('td', [_vm._v(_vm._s(_vm.summary.cost) + " zł, " + _vm._s(_vm.summary.power) + " kw")])]) : _vm._e()], 2)])]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }