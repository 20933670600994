<template>
  <div class="edit-bg p-3">
    <h6 v-if="data">Edycja zadania</h6>

    <div class="row">
      <div class="col-sm-6">
        <div class="row">
          <div class="col-4">
            <label for="cron" class="mt-1">Cron:</label>
          </div>
          <div class="col-8">
            <b-form-input id="cron" v-model="form.cron"></b-form-input>
            <small>min, godz, dzien mies, miesiac, dz tyg (0n-7)</small>
          </div>
        </div>
      </div>

      <div class="col-sm-6">
        <div class="row">
          <div class="col-6">
            <label for="delay" class="m-0 p-0">Czas działania</label>
            <br /><small class="m-0 p-0">W minutach</small>
          </div>
          <div class="col-6">
            <b-form-input id="delay" v-model="form.delay"></b-form-input>
          </div>
        </div>
      </div>
    </div>

    <!-- <pre>
      {{config}}
    </pre> -->

    <p class="my-2">
      <b-button v-if="data" @click="save()" variant="success" class="px-4"
        >Zapisz</b-button
      >
      <b-button v-else @click="add()" variant="success" class="px-4"
        >Dodaj</b-button
      >

      <b-button variant="secondary" class="ml-2" @click.prevent="close()"
        >Anuluj</b-button
      >
    </p>
  </div>
</template>
<script>
import Api from '@/components/mixins/api'

export default {
  props: {
    data: {
      type: [Object, Boolean],
      default: false
    },
    idData: {
      required: true,
      type: Object
    }
  },

  mixins: [Api],

  data() {
    return {
      form: {
        pin: this.idData.pin,
        driver: this.idData.driver,
        cron: this.data.cron || '*/2 * * * *',
        delay: this.data.delay || 0,
        active: this.data.active || 1,
        onValue: null,
        offValue: null
      }
    }
  },

  computed: {
    config() {
      return this.$store.state.config[this.idData.driver].pins[this.idData.pin]
    },

    reversed() {
      return this.config.reversed || false
    }
  },

  methods: {
    close() {
      this.$emit('close')
    },
    save() {
      console.log('aktualizacja danych', this.form)
      this.apiGet({
        type: 'scheduler',
        method: 'update',
        dbObjectId: this.data._id,
        dbQuery: this.form
      })
        .then(r => {
          this.$emit('updated', true)
          this.close()
          console.log('TCL: add -> r', r)
        })
        .catch(e => {
          console.log('TCL: add -> e', e)
        })
    },

    add() {
      this.apiGet({
        type: 'scheduler',
        method: 'create',
        dbQuery: this.form
      })
        .then(r => {
          this.$emit('added', true)
          this.close()
          console.log('TCL: add -> r', r)
        })
        .catch(e => {
          console.log('TCL: add -> e', e)
        })
    }
  },

  mounted() {
    this.form.onValue = this.reversed ? 0 : 1
    this.form.offValue = this.reversed ? 1 : 0
  }
}
</script>
<style lang="scss" scoped>
.edit-bg {
  background: rgba($color: rgb(0, 51, 85), $alpha: 0.9);
  color: #fff;
  pre {
    color: #fff;
  }
}
</style>
