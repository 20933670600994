var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    staticClass: "switch",
    class: {
      disabled: _vm.loading
    }
  }, [_vm.loading ? _c('div', {
    staticClass: "loading"
  }, [_c('i', {
    staticClass: "fas fa-circle-notch fa-spin"
  })]) : _vm._e(), _c('input', {
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": _vm.checked
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.$emit('change');
      }
    }
  }), _c('span', {
    staticClass: "slider round"
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }