<template>
  <div class="login-box" v-if="show">
    <p class="text-center">
      Aktualne hasło:<br />
      <strong>{{ password }}</strong>
    </p>
    <p>
      <input
        type="text"
        v-model="passwordField"
        @keyup.enter="setPassword"
        ref="login"
        class="form-control"
      />
    </p>
    <p>
      <button class="btn btn-success btn-block" @click="setPassword">
        Zapisz
      </button>
    </p>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      show: false,
      counter: 0,
      passwordField: '',
      interval: null
    }
  },
  computed: {
    password() {
      return localStorage.getItem('app-password') || 'brak'
    }
  },
  methods: {
    setPassword() {
      localStorage.setItem('app-password', this.passwordField)
      window.location.reload()
    }
  },
  mounted() {
    this.interval = setInterval(() => {
      if (this.counter === 10) {
        this.show = true
        this.$nextTick(() => this.$refs.login.focus())
      }
      this.counter++
    }, 1000)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  }
}
</script>

<style lang="scss" scoped>
.login-box {
  margin-top: 20px;
}
</style>
