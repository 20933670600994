var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-header",
    class: {
      dark: _vm.dark
    }
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "mr-auto"
  }, [_c('span', {
    staticClass: "toggle-button",
    on: {
      "click": function ($event) {
        return _vm.toggle();
      }
    }
  }, [_c('transition', {
    attrs: {
      "name": "fade",
      "mode": "out-in"
    }
  }, [!_vm.isOpen ? _c('i', {
    key: "1",
    staticClass: "fas fa-bars"
  }) : _c('i', {
    key: "2",
    staticClass: "fas fa-times"
  })])], 1)]), _c('transition', {
    attrs: {
      "name": "fade",
      "mode": "out-in"
    }
  }, [_vm.hidden ? _c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "d-none d-sm-block"
  }, [_c('InverterIcon')], 1), _c('ventilation-icon'), _c('shower-icon'), _c('furnace-icon'), _c('buffor-icon'), _c('weather-icon', {
    attrs: {
      "temp": true
    }
  })], 1) : _vm._e()])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }