<template>
  <div class="icon" v-if="code">
    <!--    <img :src="`http://vortex.accuweather.com/adc2010/images/slate/icons/${code}.svg`" alt="" />
 -->
    <img
      :src="
        `https://developer.accuweather.com/sites/default/files/${addZeroPrefix(
          code
        )}-s.png`
      "
      :style="{ width: `${size}px` }"
      alt=""
    />
  </div>
</template>
<script>
export default {
  props: {
    code: {
      type: [String, Number, Boolean],
      default: false
    },

    size: {
      type: [String, Number]
    }
  },

  methods: {
    addZeroPrefix(code) {
      return code < 10 ? '0' + code : code
    }
  }
}
</script>
