<template>
  <div>
    <app-switch :loading="apiLoading" :checked="btnStatus" @change="change" />
  </div>
</template>
<script>
import Api from '@/components/mixins/api'
import AppSwitch from '@/components/common/AppSwitch'

export default {
  props: {
    driver: {
      type: String,
      required: true
    },
    pin: {
      type: [Number, String],
      required: true
    },
    reversed: {
      type: Boolean,
      default: false
    },
    historyKey: {
      // klucz z historii sterownika
      type: [String, Boolean],
      default: false
    },
    autoload: {
      type: Boolean,
      default: false
    }
  },

  components: {
    AppSwitch
  },

  mixins: [Api],

  data() {
    return {
      socket: window.mBus.socket,
      btnStatus: false
    }
  },

  computed: {
    historyData() {
      if (this.historyKey && this.$store.state.driversData[this.driver]) {
        const value = this.$store.state.driversData[this.driver][
          this.historyKey
        ]
        if (this.reversed) {
          return value === 1 ? 0 : 1
        } else {
          return value
        }
      }
      return null
    }
  },

  watch: {
    historyData(value) {
      this.btnStatus = value
    }
  },

  methods: {
    change(event) {
      if (!this.apiLoading) {
        this.apiGet({
          type: 'digital',
          driver: this.driver,
          pin: this.pin,
          val: this.reversed ? (this.btnStatus ? 1 : 0) : this.btnStatus ? 0 : 1
        })
          .then(r => {
            if (this.reversed) {
              this.btnStatus = r.status.value === 1 ? 0 : 1
            } else {
              this.btnStatus = r.status.value
            }
          })
          .catch(e => {
            console.log('TCL: change -> e', e)
          })
      }
    },

    // @todo trzeba dorobić na serwerze obsługę
    getValue() {
      console.log('Bak obsługi autowczytywania')
    }
  },

  mounted() {
    if (this.autoload) {
      this.getValue()
    }

    if (this.historyKey) {
      this.btnStatus = this.historyData
    }
  }
}
</script>
