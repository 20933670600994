/* eslint-disable-file */
import Vue from 'vue'

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'
// import 'bootstrap/dist/css/bootstrap.css'
import VueApexCharts from 'vue-apexcharts'

import AppBox from '@/components/common/AppBox'
import AppDriverValue from '@/components/common/AppDriverValue'
import FunctionBtn from '@/components/helpers/FunctionBtn'
import StatChart from '@/components/helpers/stat-chart'
import TimeSettings from '@/components/helpers/TimeSettings'
import SwitchBox from '@/components/SwitchBox'


Vue.use(VueAwesomeSwiper /* { default global options } */)
Vue.use(BootstrapVue)
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

Vue.component('AppBox', AppBox)
Vue.component('app-driver-value', AppDriverValue)
Vue.component('FunctionBtn', FunctionBtn)
Vue.component('stat-chart', StatChart)
Vue.component('time-settings', TimeSettings)
Vue.component('SwitchBox',SwitchBox)
