var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "color": "#fff"
    }
  }, [_vm._l(_vm.list, function (itm) {
    return [_c('Row', {
      key: itm._id,
      attrs: {
        "data": itm,
        "idData": _vm.idData
      },
      on: {
        "toggleActive": _vm.toggleActive
      }
    })];
  }), _vm.add ? _c('add-row', {
    staticClass: "my-2",
    attrs: {
      "idData": {
        pin: _vm.pin,
        driver: _vm.driver
      }
    },
    on: {
      "added": function ($event) {
        return _vm.find();
      },
      "close": function ($event) {
        _vm.add = false;
      }
    }
  }) : _vm._e(), !_vm.add ? _c('b-button', {
    staticClass: "mt-2",
    attrs: {
      "size": "sm"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        _vm.add = !_vm.add;
      }
    }
  }, [_vm._v(" Dodaj wpis ")]) : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }