var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['monitoring-floating', {
      'monitoring-floating--active': _vm.active
    }]
  }, [!_vm.active ? _c('b-button', {
    staticClass: "expand-button",
    attrs: {
      "pill": "",
      "size": "lg",
      "variant": "light"
    },
    on: {
      "click": function ($event) {
        _vm.active = !_vm.active;
      }
    }
  }, [_c('i', {
    staticClass: "fas fa-video"
  })]) : _vm._e(), _vm.active ? _c('b-button', {
    staticClass: "close-button",
    attrs: {
      "pill": "",
      "size": "lg",
      "variant": "danger"
    },
    on: {
      "click": function ($event) {
        _vm.active = !_vm.active;
      }
    }
  }, [_c('i', {
    staticClass: "fas fa-times-circle"
  })]) : _vm._e(), _vm.active ? _c('div', {
    staticClass: "preview d-flex"
  }, [_c('camera', {
    staticClass: "camera",
    attrs: {
      "place": "entrance"
    }
  }), _c('camera', {
    staticClass: "camera",
    attrs: {
      "place": "terrace"
    }
  }), _c('camera', {
    staticClass: "camera",
    attrs: {
      "place": "garage"
    }
  })], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }