<template>
  <div
    class="buffor d-flex align-items-center justify-content-center"
    :class="{ active: $route.name === 'buffor' }"
    @click="click"
  >
    <i v-if="heatingType" class="status-icon fas" :class="heatingType" />
    <div class="icon"><i class="fas" :class="energy"></i></div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "BufforIcon",

  props: {
    noRedirect: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      size: 30,
    };
  },

  computed: {
    ...mapState(["driversData"]),
    
    energy() {
      if ("piec" in this.driversData) {
        const avg =
          (this.driversData.piec.t4 +
            this.driversData.piec.t5 +
            this.driversData.piec.t6 +
            this.driversData.piec.t4) /
          4;

        if (avg < 40) {
          return "fa-battery-empty";
        } else if (avg < 50) {
          return "fa-battery-quarter";
        } else if (avg < 60) {
          return "fa-battery-half";
        } else if (avg < 70) {
          return "fa-battery-three-quarters";
        } else if (avg < 100) {
          return "fa-battery-full";
        }
      }

      return "fa-battery-empty";
    },

    heatingType() {
      const { piec } = this.driversData || {}
      const { ppie, pomp_ciepla: pc } = piec || {}

      if (pc) return 'fa-atom'
      if (ppie) return 'fa-fire'

      return null 
    }
  },

  methods: {
    click() {
      if (this.noRedirect) {
        this.$emit("click");
      } else {
        this.$router.push({ name: "buffor" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.buffor {
  padding: 0 10px;
  height: 60px;
  margin: 0 1px;
  display: inline-block;
  transition: background 0.3s ease;
  position: relative;

  &.active {
    background: rgba($color: #000000, $alpha: 0.3);
  }
  .icon {
    i {
      display: inline-block;
      transform: rotate(-90deg);
      // display: inline-block;
      font-size: 1.4em;
    }
  }

  .status-icon {
    position: absolute;
    font-size: .6em;
    right: 7px;
    top: 15px;
    color: orange;
    z-index: 5;
  }
}
</style>
