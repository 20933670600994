var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "app"
    }
  }, [_c('main-loader'), _c('app-screen-overlay'), _c('HeaderBar'), _c('Slideout'), _c('main', {
    staticClass: "container-fluid",
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "id": "panel"
    }
  }, [_c('div', {
    staticClass: "main-content"
  }, [_c('transition', {
    attrs: {
      "name": "fade",
      "mode": "out-in"
    }
  }, [_c('router-view')], 1)], 1)]), _vm.currentRoute !== 'monitoring' ? _c('monitoring-floating') : _vm._e(), _c('settings-modal')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }