<template>
  <div class="d-flex justify-content-between mx-3 mb-1" v-if="!valueOnly">
    <div>
      {{ paramName }}
    </div>
    <strong class="ml-auto mr-3">
      {{ paramValue }}
    </strong>
    <StatBtn :driver="driver" :variable="param">
      <i class="fas fa-chart-bar"></i>
    </StatBtn>
  </div>
  <div v-else>
    {{ paramValue }}
  </div>
</template>
<script>
import StatBtn from '@/components/helpers/stat-btn'

export default {
  name: 'AppDriverValue',

  components: {
    StatBtn
  },

  props: {
    name: {
      type: [String, Boolean],
      default: false
    },

    driver: {
      type: String,
      required: true
    },

    param: {
      type: String,
      required: true
    },

    valueOnly: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    config() {
      const { config } = this.$store.state
      return config && config[this.driver]
    },

    driverData() {
      const { driversData } = this.$store.state
      return driversData && driversData[this.driver]
    },

    paramName() {
      const { name } = this.config.current[this.param] || {}
      return this.name || name || `[${this.param}]`
    },

    paramValue() {
      return this.driverData[this.param]
    }
  }
}
</script>
