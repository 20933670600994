<template>
  <div class="row">
    <div class="col-6" v-for="(param, key) in timeParams" :key="key">
      <div>
        <small>
          {{ param.label || key.toUpperCase() }}
        </small>
      </div>
      <FunctionBtn
        :driver="driver"
        type="input"
        :func="func"
        :param="key"
        size="sm"
        align="center"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    driver: {
      type: String,
      required: true
    },

    func: {
      type: String,
      required: true
    }
  },

  computed: {
    timeParams() {
      return this.$store.state.config[this.driver].functions[this.func].params
    }
  }
}
</script>
