<template>
  <div class="container">
    <div class="row mb-4">
      <AppBox header="Temperatura zewnętrzna" class="col-12">
        <stat-chart driver="piec" variable="t18" />
      </AppBox>
    </div>

    <div>
      home... glowne opcje

      <p>Io url: {{ ioUrl }}</p>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {},
  computed: {
    ioUrl() {
      return process.env.VUE_APP_IO || ' brak ...'
    }
  }
}
</script>
