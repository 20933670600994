<template>
  <div class="row">
    <div class="col col-xs-4">
      <small>Cron</small><br />
      {{ data.cron }}
    </div>

    <div class="col" @click="$emit('toggleActive', data)">
      <small>Active</small><br />
      <Status :status="data.active" />
    </div>

    <div class="col">
      <small>Next</small><br />
      <span v-if="data.next">
        {{ data.next | formatDate }}
      </span>
    </div>

    <div class="col">
      <small>Working</small><br />
      <Status :status="data.working" />
    </div>

    <div class="col pt-3">
      <b-button
        size="sm"
        variant="warning mr-2"
        @click.prevent="editForm = !editForm"
        v-if="!data.working"
      >
        E
      </b-button>
      <Remove :data="data" @removed="$parent.find()" />
    </div>

    <Edit
      class="col-12"
      :data="data"
      v-if="editForm"
      :idData="idData"
      @updated="$parent.find()"
      @close="editForm = false"
    />
    <!-- <div class="col-12">
      Edycja
    </div> -->
  </div>
</template>
<script>
import Status from './scheduler-list-ico-working'
import Remove from './scheduler-list-row-remove'
import Edit from './scheduler-list-row-edit'

export default {
  props: {
    data: {
      type: Object,
      required: true
    },
    idData: {
      required: true,
      type: Object
    }
  },

  data() {
    return {
      editForm: false
    }
  },

  components: {
    Status,
    Remove,
    Edit
  }
}
</script>
