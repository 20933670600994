var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "menu"
    }
  }, [_c('header'), _c('ul', {
    staticClass: "slideout-nav"
  }, [_c('li', [_c('router-link', {
    attrs: {
      "to": "/"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.hideMenu.apply(null, arguments);
      }
    }
  }, [_vm._v("Pulpit")])], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": {
        name: 'lights'
      }
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.hideMenu.apply(null, arguments);
      }
    }
  }, [_vm._v(" Światła ")])], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": {
        name: 'blinds'
      }
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.hideMenu.apply(null, arguments);
      }
    }
  }, [_vm._v(" Rolety ")])], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": {
        name: 'heating'
      }
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.hideMenu.apply(null, arguments);
      }
    }
  }, [_vm._v(" Ogrzewanie ")])], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": {
        name: 'settings'
      }
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.hideMenu.apply(null, arguments);
      }
    }
  }, [_vm._v(" Ustawienia ")])], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": {
        name: 'inverter'
      }
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.hideMenu.apply(null, arguments);
      }
    }
  }, [_vm._v(" Inverter ")])], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": {
        name: 'monitoring'
      }
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.hideMenu.apply(null, arguments);
      }
    }
  }, [_vm._v(" Kamery ")])], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": {
        name: 'password'
      }
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.hideMenu.apply(null, arguments);
      }
    }
  }, [_vm._v(" Hasło ")])], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }