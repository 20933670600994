<template>
  <div class="AppBox mt-4 mb-2">
    <div class="box">
      <div class="header" v-if="header" v-html="header" />

      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    header: {
      type: [String, Boolean],
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.AppBox {
  .box {
    padding-top: 25px;
    height: 100%;
  }
}
</style>
