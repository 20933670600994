<template>
  <div class="apex-container">
    <transition name="fade">
      <div class="loading" v-if="loading">
        <i class="fas fa-circle-notch fa-spin"></i>
      </div>
    </transition>

    <div class="stat-options">
      <b-button
        @click.prevent="reload()"
        variant="outline-primary"
        size="sm"
        class="btn-rounded"
      >
        <i class="fas fa-sync-alt"></i> {{ this.variable }}
      </b-button>
    </div>

    <apexchart
      ref="apexChart"
      height="150"
      type="area"
      :options="apexOptions"
      :series="series"
      :class="{ 'apex-loading': loading }"
    >
    </apexchart>
  </div>
</template>
<script>
import moment from "moment";
import { debounce } from "throttle-debounce";
import ApiMix from "@/components/mixins/api";

export default {
  props: {
    driver: {
      type: String,
    },

    variable: {
      type: String,
    },

    variableValue: {
      type: [String, Boolean],
      default: null,
    },

    groupBy: {
      type: String,
      default: "hour",
    },

    dateFrom: {
      type: String,
      default: "",
    },

    dateTo: {
      type: String,
      default: "",
    },

    dataParam: {
      type: String,
      default: "avg",
      validator: (value) => ["min", "max", "count", "avg"].includes(value),
    },
  },

  mixins: [ApiMix],

  data() {
    return {
      isDefault: true, // add last reading to chart
      loading: false,
      apexOptions: {
        // title: {
        //   text: this.driver.toUpperCase() + ' | ' + this.variable,
        //   align: 'left',
        //   style: {
        //     fontSize: '12px',
        //     color: '#fff'
        //   }
        // },
        //
        tooltip: {
          theme: "dark",
        },

        chart: {
          sparkline: {
            enabled: true,
          },
        },
        colors: ["white"],
        stroke: {
          curve: "straight",
        },
        xaxis: {
          // labels
          categories: [],
        },
      },

      series: [
        {
          name: this.variable,
          data: [0],
        },
      ],
    };
  },

  computed: {
    getDateFrom() {
      return this.dateFrom || moment().local().startOf("day").format();
    },

    getDateTo() {
      return this.dateTo || moment().local().endOf("day").format();
    },

    // current value of variable
    // getCurrentValue() {
    //   const { [this.driver]: currentDriver } =
    //     this.$store.state.driversData || {}
    //   const { [this.variable]: variable } = currentDriver || {}
    //   return variable || 0
    // },

    dateFormat() {
      switch (this.groupBy) {
        case "hour":
          return "HH DD-MM-YYYY";
        case "day":
          return "DD-MM-YYYY";
        case "month":
          return "MM-YYYY";
        default:
          return "YYYY";
      }
    },

    getVariableValue() {
      return this.variableValue || (this.dataParam === "count" ? 1 : null);
    },
  },

  watch: {
    dateFrom() {
      this.reload();
    },

    dateTo() {
      this.reload();
    },

    groupBy() {
      this.reload();
    },

    loading(value) {
      this.$emit("loading", value);
    },
  },

  mounted() {
    this.getData();
  },

  methods: {
    getData() {
      this.loading = true;
      this.$emit("data", false);

      this.apiGet(
        {
          type: "history",
          driver: this.driver,
          groupBy: this.groupBy, // hour, day, month, year
          variable: this.variable,
          variableValue: this.getVariableValue || null,
          dateFrom: this.getDateFrom,
          dateTo: this.getDateTo,
        },
        15000
      )
        .then((r) => {
          this.parseResponse(r);
        })
        .catch((e) => {
          console.log("Błąd ładowania wykresu", e);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    addZero(number) {
      return number < 10 ? "0" + number : number;
    },

    getDateFromMongoId(id) {
      let date = "";
      date += id.hour !== undefined ? this.addZero(id.hour) + " " : "";
      date += id.day !== undefined ? this.addZero(id.day) + "-" : "";
      date += id.month !== undefined ? this.addZero(id.month) + "-" : "";
      date += id.year ? id.year : "";

      return date;
    },

    parseResponse(data) {
      const formattedData = [];

      // console.log(data)

      // iterate over dates (from to)
      for (
        let m = moment(this.getDateFrom).local();
        m.isBefore(this.getDateTo);
        m.add(1, this.groupBy)
      ) {

        // empty data
        let dataToArray = {
          lp: m.format(this.dateFormat),
          max: 0,
          min: 0,
          count: 0,
          avg: 0,
        };

        // search for specific date
        data.forEach((itm) => {
          if (this.getDateFromMongoId(itm._id) === m.format(this.dateFormat)) {
            dataToArray = { lp: m.format(this.dateFormat), ...itm };

            // @brak 23 godziny, potem jest 0
            console.log(m.format(this.dateFormat), itm.avg)
          }
        });
        formattedData.push(dataToArray);
      }

      // emmit raw data
      this.$emit("data", data);

      // emmit formatted data
      this.$emit("formattedData", formattedData);

      // set categories
      const categories = formattedData.map((itm) => {
        // console.log(itm)
        return itm.lp;
      });

      this.apexOptions = {
        ...this.apexOptions,
        xaxis: {
          categories: categories,
        },
      };

      // set series
      const series = formattedData.map((itm, index) => {
        return itm[this.dataParam];
      });

      this.series = [
        {
          name: this.variable,
          data: series,
        },
      ];
    },

    reload: debounce(500, function () {
      this.getData();
    }),
  },
};
</script>

<style lang="scss" scoped>
.apex-container {
  position: relative;
  padding-top: 20px;

  .apex-loading {
    opacity: 0.2;
  }

  .loading {
    z-index: 5;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3rem;
  }

  .stat-options {
    position: absolute;
    left: 5px;
    top: 0px;
    z-index: 20;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
