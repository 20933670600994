var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-modal', {
    ref: "modal",
    attrs: {
      "title": 'Ustawienia ' + _vm.params.name,
      "hide-footer": ""
    },
    model: {
      value: _vm.showModal,
      callback: function ($$v) {
        _vm.showModal = $$v;
      },
      expression: "showModal"
    }
  }, [_vm.params ? _c('div', ['configArray' in _vm.params ? _c('div', _vm._l(_vm.params.configArray, function (itm, index) {
    return _c('div', {
      key: index,
      staticClass: "d-flex"
    }, [_c('div', {
      staticClass: "mr-auto p-2"
    }, [_vm._v(_vm._s(itm.name))]), _c('div', {
      staticClass: "p-2"
    }, [_c('FunctionBtn', {
      attrs: {
        "driver": _vm.params.driver,
        "func": _vm.params.config.functionName,
        "param": itm.param,
        "separator": _vm.params.config.separator,
        "type": itm.type
      }
    })], 1)]);
  }), 0) : _vm._e()]) : _vm._e()])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }